<script setup>
import { gsap, ScrollTrigger } from '~/vendors/gsap'
import SplitType from 'split-type'
import GlobalEmitter from '~~/glxp/utils/emitter'

import { useLoaderStore } from '~~/store/loader'

const ENTER_TIMING = 400
const loaderState = useLoaderStore()

const router = useRouter()
const needsLoading = ref(
  router.currentRoute.value.path === '/' ||
    router.currentRoute.value.path === '/spacecraft-fleet' ||
    router.currentRoute.value.path === '/spaceport-america' ||
    router.currentRoute.value.path === '/spaceflight-experience'
)

// Start the transition before loading the next page
// NOTE - We can set unique transitions by checking the 'to' meta tags
router.beforeEach((to, from, next) => {
  if (from.path !== to.path) {
    loaderState.isOpen = true

    needsLoading.value =
      to.path === '/' ||
      to.path === '/spacecraft-fleet' ||
      to.path === '/spaceport-america' ||
      to.path === '/spaceflight-experience'

    // Reset progress
    progress.value = 0

    setTimeout(() => {
      window.document.body.scrollTo(0, 0)

      switchWebglScene(to)

      next()
    }, ENTER_TIMING)
  } else {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })

    setTimeout(() => {
      next()
    }, ENTER_TIMING)
  }
})

const progress = ref(0)

// TODO - Set size depending on breakpoints
const size = ref(184)

const dasharray = computed(() => {
  return size.value * Math.PI
})
const dashoffset = computed(() => {
  return (size.value - progress.value * size.value) * Math.PI
})
const percent = computed(() => {
  return Math.round(progress.value * 100)
})
const isOpen = computed(() => loaderState.isOpen)

// Webgl loading methods
function getSceneIdFromRoute(route) {
  // If scene URL param is set take that one instead of the actual route name
  const routeName = route.query.scene || route.name.split('__')[0]

  switch (routeName) {
    case 'index':
      return 'home'

    case 'spaceport-america':
      return 'spaceportAmerica'

    case 'spacecraft-fleet':
      return 'spacecraftFleet'

    case 'spaceflight-experience':
      return 'flightExperience'

    case 'spacecraft-fleet-sandbox':
      return 'spacecraftFleet'

    case 'benchmark':
      return 'benchmark'

    case 'webgl-sandbox':
      return 'sandbox'

    default:
      return null
  }
}

/**
 * Animations
 */
const el = ref(null)
let timeline
function initIntro() {
  const { words } = new SplitType(
    el.value.querySelector('.global-loader__text-container'),
    { types: 'words' }
  )

  const dnaTimeline = gsap.timeline({ repeat: -1 })

  el.value.querySelectorAll('.global-loader__dna-img').forEach((dnaEl) => {
    dnaTimeline
      .to(dnaEl, {
        opacity: 1,
        y: 0,
        duration: 0.75,
        ease: 'elastic.out(2.5, 1)',
      })
      .to(dnaEl, {
        opacity: 0,
        y: -50,
        duration: 0.75,
        ease: 'elastic.in(2.5, 1)',
      })
  })

  timeline = gsap
    .timeline({ delay: 0.5 })
    .from(el.value.querySelector('.global-loader__progress-svg'), {
      scale: 0,
      duration: 1.5,
      ease: 'power3.out',
    })
    .from(
      words,
      {
        opacity: 0,
        duration: 1.25,
        stagger: {
          from: 'random',
          amount: 0.5,
        },
      },
      0.25
    )
    .add(dnaTimeline, 0.3)
}

/**
 * Events
 */
function switchWebglScene(targetRoute) {
  // TODO: Rework switchWebglScene calls with front-end for a more robust solution
  let target = targetRoute ? targetRoute : router.currentRoute.value

  const sceneId = getSceneIdFromRoute(target)

  if (sceneId) {
    GlobalEmitter.emit('webgl_set_scene', { sceneId })
  }
}

function onWebglLoadingStart() {}

function loadingComplete() {
  loaderState.isOpen = false
  // ScrollTrigger.refresh() // this is already getting called globally on loader animation end
}

function onWebglLoadingProgress(payload) {
  // Skip the loading animation if no webGL
  if (!needsLoading.value) {
    loadingComplete()
    return
  }

  gsap.to(progress, {
    value: payload.progress / 100,
    duration: 2,
    onComplete: function () {
      if (progress.value === 1) {
        loadingComplete()
      }
    },
  })
}

function onWebglLoadingLoaded() {}

function handleBeforeEnter() {
  timeline.pause(0)
}

function handleEnter() {
  timeline.play()
  // Lock Scroll
  document.documentElement.classList.add('no-scroll')
}

function handleBeforeLeave() {
  // TODO: Temp
  if (
    getSceneIdFromRoute(router.currentRoute.value) === 'home' ||
    getSceneIdFromRoute(router.currentRoute.value) === 'sandbox'
  ) {
    // Inside beforeLeave to make the transition smoother
    // GlobalEmitter.emit('webgl_home_header_intro')
    setTimeout(() => {
      GlobalEmitter.emit('webgl_home_header_intro')
    }, 500)
  }
}

function handleAfterLeave() {
  timeline.pause()
  // Unlock Scroll
  document.documentElement.classList.remove('no-scroll')
  GlobalEmitter.emit('loader_animation_end')
}

// Lifecycle
onMounted(() => {
  initIntro()
  handleEnter()
  switchWebglScene()

  GlobalEmitter.on('webgl_loading_start', onWebglLoadingStart)
  GlobalEmitter.on('webgl_loading_progress', onWebglLoadingProgress)
  GlobalEmitter.on('webgl_loaded', onWebglLoadingLoaded)
})
</script>

<template>
  <Transition
    name="loader"
    :duration="{ enter: ENTER_TIMING, leave: 400 }"
    @before-enter="handleBeforeEnter"
    @after-enter="handleEnter"
    @before-leave="handleBeforeLeave"
    @after-leave="handleAfterLeave"
  >
    <aside v-show="isOpen" class="global-loader" ref="el">
      <div class="global-loader__progress">
        <svg
          class="global-loader__progress-svg"
          :viewBox="`0 0 ${size} ${size}`"
          fill="none"
          aria-label="Global Loader" aria-labelledby="iconLoader iconLoaderDesc" role="img">
          <title id="iconLoader">Global Loader</title>
          <desc id="iconLoaderDesc">An illustrated Global Loader.</desc>
          <defs>
            <linearGradient
              id="loader_gradient"
              x1="0.6"
              y1="0"
              x2="0.4"
              y2="1"
            >
              <stop stop-color="#D90AFB" />
              <stop offset="1" stop-color="#31AFD4" />
            </linearGradient>
          </defs>

          <circle
            class="global-loader__progress-outline"
            :r="size * 0.5"
            cx="50%"
            cy="50%"
            stroke-width="1.5"
            :style="{
              'stroke-dasharray': dasharray,
            }"
          />

          <circle
            class="global-loader__progress-bar"
            :r="size * 0.5"
            cx="50%"
            cy="50%"
            stroke="url(#loader_gradient)"
            stroke-width="1.5"
            :style="{
              'stroke-dasharray': dasharray,
              'stroke-dashoffset': dashoffset,
            }"
          />
        </svg>

        <div class="global-loader__dna">
          <img
            class="global-loader__dna-img global-loader__dna-img--0"
            src="~assets/images/icons/dna_0.svg" alt=""
          />
          <img
            class="global-loader__dna-img global-loader__dna-img--1"
            src="~assets/images/icons/dna_1.svg" alt=""
          />
          <img
            class="global-loader__dna-img global-loader__dna-img--2"
            src="~assets/images/icons/dna_2.svg" alt=""
          />
          <img
            class="global-loader__dna-img global-loader__dna-img--3"
            src="~assets/images/icons/dna_3.svg" alt=""
          />
          <img
            class="global-loader__dna-img global-loader__dna-img--4"
            src="~assets/images/icons/dna_4.svg" alt=""
          />
          <img
            class="global-loader__dna-img global-loader__dna-img--5"
            src="~assets/images/icons/dna_5.svg" alt=""
          />
          <img
            class="global-loader__dna-img global-loader__dna-img--6"
            src="~assets/images/icons/dna_6.svg" alt=""
          />
          <img
            class="global-loader__dna-img global-loader__dna-img--7"
            src="~assets/images/icons/dna_7.svg" alt=""
          />
        </div>
      </div>

      <div class="global-loader__text-container">
        <p>{{ $t('loader.copy') }}</p>
      </div>
    </aside>
  </Transition>
</template>

<style lang="scss">
// Vendors
@import 'styles/vendors/sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/functions';
@import 'styles/utils/timing-functions';

.global-loader {
  background: radial-gradient(
      104.31% 242.2% at 124.65% -26.46%,
      rgba(200, 10, 251, 0.2) 0%,
      rgba(200, 10, 251, 0) 100%
    ),
    radial-gradient(
      64.07% 82.55% at 0.87% 100%,
      rgba(200, 10, 251, 0.3) 0%,
      rgba(200, 10, 251, 0) 100%
    ),
    radial-gradient(
      81.85% 133.76% at 100% 100%,
      rgba(21, 42, 244, 0.26) 0%,
      rgba(21, 42, 244, 0) 100%
    ),
    #17022c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-layers, 'loader');

  &__progress {
    width: 184px;
    height: 184px;
    position: relative;
  }

  &__progress-svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    overflow: visible;
  }

  &__progress-outline {
    fill: none;
    stroke: rgba($brand-white, 0.15);
  }

  &__progress-bar {
    fill: none;
  }

  &__dna {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__dna-img {
    grid-area: 1 / 1;
    margin: 0 auto;
    display: block;
    width: auto;
    height: 30px;
    transform: translateY(50px);
    opacity: 0;
    // transition: transform 0.2s ease, opacity 0.2s ease;
  }

  &__text-container {
    max-width: 50%;
    margin: 0 auto;
    margin-top: 48px;

    @include mq($from: 'm') using ($from) {
      max-width: 100%;
    }
  }

  p {
    text-align: center;
    // margin-top: 172px;
  }

  // Transitions
  &.loader-enter-active,
  &.loader-leave-active {
    transition: clip-path 0.4s $ease-in-out-circ;
  }

  &.loader-enter-from {
    clip-path: polygon(0 calc(100% + 300px), 100% 100%, 100% 100%, 0 100%);
  }

  &.loader-enter-to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  &.loader-leave-from {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% + 300px));
  }

  &.loader-leave-to {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}
</style>
