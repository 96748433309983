<script setup>
import { useModalStore } from '~/store/modal'
const modalStore = useModalStore()

// Handlers
const handleClose = () => {
  modalStore.modal = ''
}
</script>

<template>
  <Modal name="sign-up" use-transition>
    <FlyWithUsForm @on-close="handleClose" />
  </Modal>
</template>

<style lang="scss"></style>
