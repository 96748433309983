<script setup>
import { gsap } from '~/vendors/gsap'
import SplitType from 'split-type'
import GlobalEmitter from '~/glxp/utils/emitter'
import { onLoadAnimationEnd } from '~/composables/onLoadAnimationEnd'
import { eyebrowAnimation } from '~/utils/animations'
import { lerp, clamp } from '~/glxp/utils/math'
import { Vec2 } from '~/glxp/ogl/math/Vec2'
import { useIsMobile } from '~~/composables/useIsMobile'
import { useMouse } from '~~/composables/useMouse'

// const localePath = useLocalePath()
const isMobile = useIsMobile()
const el = ref(null)
const isInView = ref(false)

// Bounds
const bounds = reactive({
  el: {
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
})

function getBoundsIfInView() {
  if (isInView.value) getBounds()
}

function getBounds() {
  if (!el.value) return
  const {
    width: elWidth,
    height: elHeight,
    top: elTop,
    right: elRight,
    bottom: elBottom,
    left: elLeft,
  } = el.value.getBoundingClientRect()

  bounds.el.width = elWidth
  bounds.el.height = elHeight
  bounds.el.top = elTop
  bounds.el.right = elRight
  bounds.el.bottom = elBottom
  bounds.el.left = elLeft
}

/**
 * Inview animation
 */
let timeline
function initTimeline() {
  const { chars } = new SplitType(
    el.value.querySelector('.global-footer__header'),
    { types: 'chars, words' }
  )
  const divider = el.value.querySelector('.global-footer__divider')
  timeline = gsap
    .timeline({ paused: true })
    .addLabel('eyebrow')
    .from(chars, eyebrowAnimation, 'eyebrow')
    .addLabel('divider', 'eyebrow+=0.25')
    .from(
      divider,
      {
        opacity: 0.5,
        scaleX: 0,
        duration: 1.5,
        ease: 'power2.out',
      },
      'divider'
    )
}

/**
 * Mouseover animation
 */

let running = false
const mousePosition = useMouse()
const lerpedPosition = new Vec2(0.5, 0.5)

function animate() {
  if (running) requestAnimationFrame(animate)
  if (!el.value) return
  const bg = el.value.querySelector('.global-footer__bg')
  if (!running) {
    bg.style.transform = null
    return
  }

  const { top, right, bottom, left, width, height } = bounds.el
  const { x, y } = mousePosition

  lerpedPosition.set(
    lerp(lerpedPosition.x, (x - left) / (right - left), 0.1),
    lerp(lerpedPosition.y, (y - top) / (bottom - top), 0.1)
  )

  const image = el.value.querySelector('.global-footer__header-image')
  const opacity = clamp(
    0,
    1,
    clamp(
      150 /
        new Vec2(x, y).distance(new Vec2(left + width / 2, top + height / 2)),
      0,
      1
    )
  )
  image.style.opacity = opacity

  const translationRange = 15
  const bgX = clamp(
    (lerpedPosition.x * 2 - 1) * translationRange - 50,
    -50 - translationRange,
    -50 + translationRange
  )
  const bgY = clamp(
    (lerpedPosition.y * 2 - 1) * translationRange - 50,
    -50 - translationRange,
    -50 + translationRange
  )

  bg.style.transform = `translate3d(${bgX}%, ${bgY}%, 0)`
}

/**
 * Events
 */
function onEnter() {
  timeline?.play()
  isInView.value = true

  if (isMobile.value) return
  running = true
  animate()
}

function onExit() {
  timeline?.pause(0)
  isInView.value = false

  if (isMobile.value) return
  running = false
}

onLoadAnimationEnd(() => {
  initTimeline()
})

onMounted(() => {
  getBounds()
  GlobalEmitter.on('debouncedResize', getBounds)
  window.addEventListener('scroll', getBoundsIfInView)
})

onBeforeUnmount(() => {
  GlobalEmitter.off('debouncedResize', getBounds)
  window.removeEventListener('scroll', getBoundsIfInView)
  onExit()
})

watch(isMobile, async (value) => {
  if (value) {
    running = false
  }
})
</script>

<template>
  <footer ref="el" class="global-footer container">
    <InView @enter="onEnter" @exit="onExit">
      <div class="global-footer__header">
        <div class="global-footer__header-image" />
        <p>{{ $t('footer.catchphrase') }}</p>
      </div>
    </InView>

    <div class="global-footer__bg" />

    <div class="global-footer__divider" />

    <div class="global-footer__footer">
      <ul class="global-footer__nav">
        <li class="global-footer__entry">
          <NuxtLink
            class="global-footer__entry-link gtm-vgms-footer-navigation-faq"
            :to="localePath('/faq')"
          >
            <span>{{ $t('footer.faq') }}</span>
          </NuxtLink>
        </li>
        <li class="global-footer__entry">
          <NuxtLink
            class="global-footer__entry-link gtm-vgms-footer-navigation-gallery"
            :to="localePath('/gallery')"
          >
            <span>{{ $t('footer.gallery') }}</span>
          </NuxtLink>
        </li>
        <li class="global-footer__entry">
          <NuxtLink
            class="global-footer__entry-link gtm-vgms-footer-navigation-gallery"
            :to="localePath('/privacy-policy')"
          >
           <span>{{ $t('menu["Privacy Policy"]') }}</span>
          </NuxtLink>
        </li>
        <li class="global-footer__entry">
          <NuxtLink
            class="global-footer__entry-link gtm-vgms-footer-navigation-gallery"
              :to="localePath('/terms-and-conditions')"
          >
            <span>{{ $t('menu["Terms of Use"]') }}</span>
          </NuxtLink>
        </li>
        <li class="global-footer__entry">
          <NuxtLink
            class="global-footer__entry-link gtm-vgms-footer-navigation-gallery"
             :to="localePath('/cookie-notice')"
          >
            <span>{{ $t('menu["Cookie Notice"]') }}</span>
          </NuxtLink>
        </li>
        <li class="global-footer__entry">
          <NuxtLink
            class="global-footer__entry-link gtm-vgms-footer-navigation-gallery"
            :to="localePath('/ada-statement')"
          >
              <span>{{ $t('menu["ADA Statement"]') }}</span>
          </NuxtLink>
        </li>
        <!-- <li class="global-footer__entry">
          <a href="#">
            <span>{{ $t('footer.merch') }}</span>
          </a>
        </li> -->
      </ul>

      <ul class="global-footer__socials">
        <li class="global-footer__social">
          <a
            class="global-footer__social-link gtm-vgms-footer-social-instagram"
            href="https://www.instagram.com/virgingalactic"
            target="_blank"
            rel="noreferrer"
          >
            <span class="u-visually-hidden">{{ $t('footer.Instagram') }}</span>
            <Icon name="instagram" />
          </a>
        </li>

        <li class="global-footer__social">
          <a
            class="global-footer__social-link gtm-vgms-footer-social-twitter"
            href="https://twitter.com/virgingalactic"
            target="_blank"
            rel="noreferrer"
          >
            <span class="u-visually-hidden">{{ $t('footer.Twitter') }}</span>
            <Icon name="twitter" />
          </a>
        </li>

        <li class="global-footer__social">
          <a
            class="global-footer__social-link gtm-vgms-footer-social-youtube"
            href="https://www.youtube.com/@VirginGalactic"
            target="_blank"
            rel="noreferrer"
          >
            <span class="u-visually-hidden">{{ $t('footer.Youtube') }}</span>
            <Icon name="youtube" />
          </a>
        </li>

        <li class="global-footer__social">
          <a
            class="global-footer__social-link gtm-vgms-footer-social-facebook"
            href="https://www.facebook.com/VirginGalactic"
            target="_blank"
            rel="noreferrer"
          >
            <span class="u-visually-hidden">{{ $t('footer.Facebook') }}</span>
            <Icon name="facebook" />
          </a>
        </li>

        <li class="global-footer__social">
          <a
            class="global-footer__social-link gtm-vgms-footer-social-tiktok"
            href="https://www.tiktok.com/@virgingalactic"
            target="_blank"
            rel="noreferrer"
          >
            <span class="u-visually-hidden">{{ $t('footer.TikTok') }}</span>
            <Icon name="tiktok" />
          </a>
        </li>

        <li class="global-footer__social">
          <a
            class="global-footer__social-link gtm-vgms-footer-social-linkedin"
            href="https://www.linkedin.com/company/virgin-galactic"
            target="_blank"
            rel="noreferrer"
          >
            <span class="u-visually-hidden">{{ $t('footer.Linkedin') }}</span>
            <Icon name="linkedin" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss">
@use 'sass:math';

// Vendors
@import 'styles/vendors/sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/functions';

.global-footer {
  overflow: hidden;
  padding-top: 84px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #080711;

  @include mq($from: 'm') using($from) {
    padding-bottom: 116px;
  }

  @include mq($from: 'l') using($from) {
    padding-top: 138px;
    padding-bottom: 0;
  }

  &__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include mq($from: 'l') using ($from) {
      top: 50%;
      left: 50%;
      width: 150%;
      height: 150%;
    }
    background-image: radial-gradient(
        189.06% 295.94% at 82.88% 209.58%,
        rgba(21, 42, 244, 0.15) 0%,
        rgba(21, 42, 244, 0) 100%
      ),
      radial-gradient(
        283.64% 307.92% at 48.06% 222.29%,
        rgba(106, 24, 247, 0.15) 0%,
        rgba(106, 24, 247, 0) 100%
      ),
      radial-gradient(
        73.96% 449.37% at 88.44% -34.38%,
        rgba(200, 10, 251, 0.2) 0%,
        rgba(200, 10, 251, 0) 100%
      );
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 290px;
    height: 168px;
    position: relative;
    margin: 0 auto;

    @include mq($from: 'm') using($from) {
      max-width: 100%;
    }

    @include mq($from: 'l') using($from) {
      height: 88px;
    }

    &-image {
      content: '';
      background: url('~/assets/images/icons/spaceship.svg') no-repeat center
        center;
      background-size: contain;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      mix-blend-mode: overlay;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__header p {
    font-size: 14px;
    font-weight: $font-weight-3G;
    line-height: math.div(26, 14);
    letter-spacing: 0.24em;
    text-align: center;
    text-transform: uppercase;
    color: #7d61b4;
    margin: 0 auto;
    position: relative;

    @include mq($from: 'l') using($from) {
      font-size: 12px;
      line-height: math.div(14, 12);
      letter-spacing: 0.5em;
      text-shadow: 0px 1px 2px rgba($brand-black, 0.12);
    }
  }

  &__divider {
    margin-top: 74px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    flex-shrink: 0;
    width: 269px;
    height: 40px;
    margin: 74px auto 0;
    overflow: hidden;

    @include mq($from: 'm') using($from) {
      width: 100%;
    }

    @include mq($from: 'l') using($from) {
      margin: 100px auto 0;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      grid-area: 1 / 1;
      justify-self: center;
      align-self: center;
    }

    &::before {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #c80afb 0%,
        rgba(200, 10, 251, 0.63) 31.77%,
        rgba(200, 10, 251, 0.36) 60.94%,
        rgba(200, 10, 251, 0) 99.94%
      );

      width: 100%;
      height: 80px;
      opacity: 0.07;

      @include mq($from: 'l') using($from) {
        width: gs(18, 17, $from);
      }
    }

    &::after {
      background: linear-gradient(
        89.94deg,
        rgba(125, 96, 180, 0) -2.15%,
        #7d60b4 50.7%,
        rgba(125, 96, 180, 0) 99.85%
      );
      width: 100%;
      height: 4px;

      @include mq($from: 'l') using($from) {
        background: linear-gradient(
          89.94deg,
          rgba(125, 96, 180, 0) -2.15%,
          #7d60b4 50.7%,
          rgba(125, 96, 180, 0.947036) 53.3%,
          rgba(125, 96, 180, 0) 99.85%
        );
        width: 100%;
      }
    }
  }

  &__footer {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;

    @include mq($from: 'l') using($from) {
      margin-top: 0px;
      padding: 56px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq($from: 'l') using($from) {
      flex-direction: row;
      flex-shrink: 0;
    }
  }

  &__entry {
    display: flex;
    align-items: center;
  }

  &__entry:not(:first-child) {
    margin-top: 32px;

    @include mq($from: 'l') using($from) {
      margin-top: 0;

      &::before {
        content: '';
        border-radius: 50%;
        margin: 0 14px;
        display: block;
        width: 3px;
        height: 3px;
      }
    }
  }

  &__entry-link {
    font-size: 18px;
    font-weight: $font-weight-3G;
    line-height: math.div(24, 18);
    color: $brand-white;
    transition: color 0.25s ease;

    &:hover {
      color: $brand-magenta;
    }

    @include mq($from: 'l') using($from) {
      font-size: 15px;
      text-shadow: 0px 1px 2px rgba($brand-black, 0.12);
    }
  }

  &__socials {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($from: 'l') using($from) {
      margin-top: 0;
    }
  }

  &__social:not(:first-child) {
    margin-left: 36px;

    @include mq($from: 'l') using($from) {
      margin-left: 14px;
    }
  }

  &__social-link {
    color: $brand-white;
    display: flex;
    transition: color 0.25s ease;

    &:hover {
      color: $brand-magenta;
    }
  }

  &__social .svg {
    width: 24px;
    height: 24px;

    @include mq($from: 'l') using($from) {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
