<script setup>
import { gsap } from '~/vendors/gsap'
import { useModalStore } from '~/store/modal'
const modalStore = useModalStore()
const activeModal = computed(() => modalStore.modal)
import { useIsMobile } from '~/composables/useIsMobile'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  useTransition: {
    type: Boolean,
    default: false,
  },
})

// Handlers
const el = ref(null)
const SWIPE_DURATION = 750
const isMobile = useIsMobile()
const swipeAngle = computed(() => (isMobile.value ? 60 : 200))
function handleEnter() {
  if (props.useTransition) {
    const fromPath = `polygon(
      0% calc(100% + ${swipeAngle.value}px),
      100% 100%,
      100% 100%,
      0% 100%
    )`
    const toPath = `polygon(
      0% calc(0% + 0px),
      100% 0%,
      100% 100%,
      0% 100%
    )`
    gsap.timeline({ clearProps: true }).fromTo(
      el.value,
      { clipPath: fromPath },
      {
        clipPath: toPath,
        duration: SWIPE_DURATION / 1000,
        ease: 'power2.in',
      }
    )
  }

  // Lock Scroll
  document.body.classList.add('no-scroll')
}

function handleLeave() {
  if (props.useTransition) {
    const fromPath = `polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      0% calc(100% + ${swipeAngle.value}px)
    )`
    const toPath = `polygon(
      0% 0%,
      100% 0%,
      100% 0%,
      0% calc(0% + 0px)
    )`

    gsap.timeline({ clearProps: true }).fromTo(
      el.value,
      { clipPath: fromPath },
      {
        clipPath: toPath,
        duration: SWIPE_DURATION / 1000,
        ease: 'power2.in',
      }
    )
  }

  // Unlock Scroll
  document.body.classList.remove('no-scroll')
}
</script>

<template>
  <Transition
    :name="name"
    :duration="{ enter: 0, leave: SWIPE_DURATION }"
    @enter="handleEnter"
    @leave="handleLeave"
  >
    <aside v-show="activeModal === name" ref="el" class="modal">
      <slot />
    </aside>
  </Transition>
</template>

<style lang="scss" scoped>
// Utils
@import 'styles/utils/variables';

.modal {
  background-color: $brand-black;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-layers, 'modal');
}
</style>
