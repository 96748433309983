<script setup>
import { useModalStore } from '~/store/modal'
const modalStore = useModalStore()

const props = defineProps({
  outline: {
    type: Boolean,
    default: false,
  },
})

// Handlers
const handleClick = () => {
  modalStore.modal = 'sign-up'
}
</script>

<template>
  <ButtonPill
    class="fly-with-us-button"
    icon="chevron-right"
    animate
    :outline="outline"
    @click="handleClick"
  >
    <slot />
  </ButtonPill>
</template>

<style lang="scss">
@import '~/styles/vendors/sass-mq/mq';
.fly-with-us-button {
  @include mq($until: 'm') using ($from) {
    padding: 0 22px;
  }
}
</style>
