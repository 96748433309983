<template>
  <h1 class="header-logo">
    <span class="u-visually-hidden">Virgin Galactic</span>
  </h1>
</template>

<style lang="scss">
@import 'styles/vendors/sass-mq/mq';

.header-logo {
  width: 56px;
  height: 47px;
  margin-left: -5px;
  background-image: url('~/assets/images/logo-mobile.png');
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;

  @include mq($from: 'm') using ($from) {
    width: 170px;
    height: 41px;
    margin-left: -10px;
    background-image: url('~/assets/images/logo.svg');
  }
}
</style>
