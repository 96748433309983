<script>
import GlobalEmitter from '~/glxp/utils/emitter'
import DebugController from '~/glxp/debug/debugController'

export default {
  name: 'WebGLContainer',

  data() {
    return {
      currentSceneId: '',
    }
  },

  mounted() {
    // WebGL Manager
    this.$webglManager.init(this.$refs.canvas)

    // Render Loop
    // TODO: Don't forget to update when raf should start
    this.rafStart()

    // Events
    this.addEvents()
  },

  beforeDestroy() {
    this.removeEvents()
  },

  methods: {
    // Rendering
    rafStart() {
      this.$raf.subscribe(
        'canvas',
        this.$webglManager.render.bind(this.$webglManager)
      )
    },
    rafStop() {
      this.$raf.unsubscribe('canvas')
    },

    // Lifecycle
    loadScene(sceneId) {
      GlobalEmitter.emit('webgl_loading_start')

      const scene = this.$webglManager.getScene(sceneId)

      if (scene) {
        if (scene.loaded) {
          // console.log('WEBGL - Activating scene ', sceneId)

          this.currentSceneId = sceneId
          if (scene.reset) scene.reset()
          this.$webglManager.activate(sceneId)
          GlobalEmitter.emit('webgl_loading_progress', { progress: 100 })
          GlobalEmitter.emit('webgl_loaded')
          // Needs to be called here in order to properly apply saved configs,
          // as it is called in loadScene() > scene.load()
          DebugController.onLoaded()
        } else {
          // console.log('WEBGL - Loading scene ', sceneId)

          this.$webglManager.loadScene(sceneId).then(() => {
            // console.log('WEBGL - Activating scene ', sceneId)

            this.currentSceneId = sceneId
            if (scene.reset) scene.reset()
            this.$webglManager.activate(sceneId)
          })
        }
      } else {
        // console.error('WEBGL - Error, scene not found', sceneId)
      }
    },
    setScene({ sceneId }) {
      // Add: Dispose of current scene
      // Should also reset scene?
      const currentScene = this.$webglManager.getScene(this.currentSceneId)
      if (currentScene && currentScene.active) {
        this.$webglManager.desactivate(this.currentSceneId)
      }

      this.loadScene(sceneId)
    },

    // Events
    addEvents() {
      GlobalEmitter.on('webgl_raf_start', this.rafStart)
      GlobalEmitter.on('webgl_raf_stop', this.rafStop)
      GlobalEmitter.on('webgl_set_scene', this.setScene)
    },
    removeEvents() {
      GlobalEmitter.off('webgl_raf_start', this.rafStart)
      GlobalEmitter.off('webgl_raf_stop', this.rafStop)
      GlobalEmitter.off('webgl_set_scene', this.setScene)
    },
  },
}

/**
 * Tentative list of events:
 *
 * General :
 * - 'webgl_raf_start' (): Start rendering loop with RAF
 * - 'webgl_raf_stop' (): Stop rendering loop with RAF
 * - 'webgl_set_scene' ({ sceneId: string }): Dispose of current scene and activate target one
 *   - List of scene ids: 'home', 'spaceportAmerica', 'fleet'
 * - [WGL] 'webgl_loading_start' (): Emit when a request to load a scene is made
 * - [WGL] 'webgl_loading_progress' ({ progress: number }): Emit everytime an asset is loaded
 * - [WGL] 'webgl_loaded' (): Emit when assets are loaded and first draw done if enabled
 * - [WGL] 'webgl_hotspot_position' (): Emit in render loop with each active hotspot screenspace position.
 *   - TODO: Arrays will be in a separate file, you will be able to check the structure here.
 *
 * Home :
 * - 'webgl_home_switch_subscene' ({ isInstant: bool }): Switch home subscenes
 * - 'webgl_home_header_intro' (): Play intro animation of home header
 * - 'webgl_home_header_progress' ({ progress: float }): Set progress on home header subscene
 * - 'webgl_home_spaceport_progress' ({ progress: float }): Set progress on home spaceport subscene
 *
 * Spaceport America:
 * - 'webgl_spaceport_progress' ({ progress: float }): Set progress on the spaceport scene
 *
 * Spacecraft fleet :
 * - 'webgl_fleet_set_subscene' ({ id: string, isInstant: bool }): Transition to targeted subscene
 * (Should have a 'webgl_fleet_prev_subscene' and 'webgl_fleet_next_subscene' ?)
 * - 'webgl_fleet_eve_progress' ({ progress: float }): Set progress on fleet eve subscene
 * - 'webgl_fleet_unity_progress' ({ progress: float }): Set progress on fleet unity subscene
 *
 * Flight Experience :
 * - 'webgl_flight_experience_progress' ({ progress: float }): Set progress on the flight experience scene
 * - 'webgl_flight_experience_hotspots' ({ hotspots: array }): Send list of generated hotspots
 * - 'webgl_flight_experience_hotspots_request': Triggers 'webgl_flight_experience_hotspots'
 */
</script>

<template>
  <div ref="canvas" class="webgl-container"></div>
</template>

<style lang="scss">
.webgl-container {
  position: sticky;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  // height: var(--real-height, 100vh);
  height: 100vh;
  will-change: transform;
  overflow: hidden;
}
</style>
